.overflow-hidden {
	overflow: hidden;
}
.br-10 {
	border-radius: 10px !important;
}
.border-transparent {
	border: transparent !important;
}
.h-100 {
	height: 100% !important;
}

.w-max-c {
	width: max-content;
}
.fd-column {
	flex-direction: column;
}
.green {
	color: #10bb35;
}
.red {
	color: #f97e7e;
}
.fw-500 {
	font-weight: 500;
}
.tableMain .tableTitle {
	color: var(--Grey-Dark, #797979);
	font-size: 16px;
}

.css-1g66d9k-MuiSwitch-root
	.MuiSwitch-switchBase.Mui-checked
	+ .MuiSwitch-track {
	background-color: var(--mainColor) !important;
}

.dropdown-menu {
	background-color: var(--lightGrayColor04);
	backdrop-filter: blur(5px);
	color: var(--whiteColor);
}
.dropdown-item {
	color: var(--whiteColor);
}

.dashboardPage .pagePath {
	font-size: 16px;
	color: #afafaf;
	font-weight: 500;
	/* font-family: "Exo"; */
	margin-bottom: 20px;
}
.dashboardPage .dashBannerMain.dashBannerMain2 {
	background: url("../../../images/homeBanner2.png");
	background-size: cover;
	background-position: top center;
	text-align: right;
}
.dashboardPage .dashBannerMain.manageMain {
	padding: 60px 30px;
	background: url("../../../images/manageBanner.png");
	background-size: cover;
	background-position: top center;
	border-radius: 16px;
}
.dashboardPage .dashBannerMain {
	padding: 60px 30px;
	background: url("../../../images/dashBanner.png");
	background-size: cover;
	background-position: top center;
	border-radius: 16px;
}
.dashboardPage .dashBannerMain .title {
	font-size: 18px;
	color: #afafaf;
	font-weight: 500;
	margin-bottom: 20px;
	/* font-family: "Exo"; */
}
.dashboardPage .dashBannerMain.dashBannerMain2 .head {
	margin-left: auto;
}
.dashboardPage .dashBannerMain .head {
	font-size: 40px;
	letter-spacing: 3px;
	line-height: 50px;
	color: #ffffff;
	font-weight: 500;
	max-width: 350px;
	/* font-family: "Exo"; */
	/* margin-left: auto; */
}
.dashboardPage .currentCreditMain {
	padding: 20px 30px;
	border-radius: 16px;
	border-radius: 22px;
	background-image: linear-gradient(128deg, #012025 0%, #0b0b0b 100%);
	border: 1px solid #0690a3;
	position: relative;
}
.border-radius-22 {
	border-radius: 22px;
}
.border-blue {
	border: 1px solid #0690a3 !important;
}
.br-12 {
	border-radius: 12px;
}
.dashboardPage .currentCreditMain .line {
	background-color: #0b3d44;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	height: 2px;
	width: 100%;
}
.dashboardPage .currentCreditMain .line span {
	position: absolute;
	height: 2px;
	left: 30px;
	width: 155px;
	background: var(--mainColor);
}
.dashboardPage .currentCreditMain .title {
	font-size: 18px;
	color: #afafaf;
	font-weight: 500;
	/* font-family: "Exo"; */
}
.dashboardPage .currentCreditMain .price {
	font-size: 50px;
	line-height: 70px;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 35px;
	/* font-family: "Exo"; */
}

.dashboardPage .currentCreditMain .percent {
	font-size: 18px;
	line-height: 53px;
	color: #ffffff;
	font-weight: 500;
	/* font-family: "Exo"; */
}
.dashboardPage .currentCreditMain .percBar {
	border-radius: 5px;
	background-color: #535353;
	height: 6px;
	position: relative;
}
.dashboardPage .currentCreditMain .percBar span {
	width: 80%;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	border-radius: 5px;
	background-image: linear-gradient(270deg, #18dffb 0%, #0d7381 100%);
}
.dashboardPage .addCreditMain {
	padding: 82px 30px;
	border-radius: 22px;
	background-image: linear-gradient(
		-32deg,
		#0f0f0f 0%,
		#1a1a1a 98%,
		#1a1a1a 100%
	);
	border: 1px solid #535353;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dashboardPage .addCreditMain .content {
	text-align: center;
}
.dashboardPage .manageForm {
	border-radius: 22px;
	background-image: linear-gradient(
		-32deg,
		#0f0f0f 0%,
		#1a1a1a 98%,
		#1a1a1a 100%
	);
	border: 1px solid #535353;
	padding: 16px;
}
.dashboardPage .manageForm .head {
	color: #fff;
	font-size: 24px;
	text-transform: uppercase;
}
.dashboardPage .manageForm .confRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	color: #afafaf;
}
.dashboardPage .manageForm .confRow .value {
	text-align: right;
}
.dashboardPage .content .credit {
	font-size: 18px;
	color: #afafaf;
	font-weight: 500;
	max-width: 246px;
	margin-left: auto;
	margin-right: auto;
}
.dashboardPage .content .addMain {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-image: linear-gradient(-30deg, #18e1fd 0%, #037281 100%);
	border: 2px solid #292929;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 40px;
	margin: auto;
	margin-bottom: 10px;
	cursor: pointer;
}
.dashboardPage .content .addMain input.file {
	display: none;
}
.box-shodow-blue {
	box-shadow: 0px 0px 10px 1px rgba(6, 144, 163, 0.65) !important;
}

.billingPage .topupCard {
	border-radius: 22px;
	background: url("../../../images/topupBanner.png");
	background-size: 100% 100%;
	background-position: top center;
	background-repeat: no-repeat;
	padding: 30px;
	text-align: center;
	border-top: 1px solid #0690a3;
	/* background-image: linear-gradient(-32deg, #0f0f0f 0%, #1a1a1a 98%, #1a1a1a 100%); */
}
.billingPage .topupCard .title {
	font-size: 18px;
	color: #ffffff;
	font-weight: 500;
	/* font-family: "Exo"; */
	text-align: center;
}
.billingPage .topupCard .logoMain {
	display: flex;
	align-items: center;
	justify-content: center;
}
.billingPage .topupCard .logoMain .logoDiv {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	overflow: hidden;
	margin: 20px;
}
.billingPage .topupCard .logoMain .logoDiv img {
	width: 100%;
	height: auto;
}
.billingPage .topupCard .amount {
	font-size: 30px;
	font-weight: bold;
	color: #fff;
}
.billingPage .topupCard .desc {
	font-size: 15px;
	color: #afafaf;
	font-weight: 500;
	/* font-family: "Exo"; */
	text-align: center;
	margin-bottom: 15px;
}
.billingPage .topupCard .goDoc {
	font-size: 15px;
	color: #ffffff;
	font-weight: 500;
	/* font-family: "Exo"; */
	text-align: center;
	border: 1px solid #fff;
	border-radius: 25px;
	padding: 10px 25px;
	background-color: transparent;
}
.billingPage table {
	color: #fff;
}
.billingPage table th {
	text-transform: uppercase;
	text-decoration: underline;
	font-size: 12px;
	color: #afafaf;
	font-weight: 500;
	padding: 20px 15px;
	/* font-family: "Exo"; */
	border-color: #535353 !important;
}
.billingPage table td {
	padding: 20px 15px;
	font-size: 12px;
	color: #ffffff;
	font-weight: 500;
	/* font-family: "Exo"; */
	vertical-align: middle;
	border-color: #535353 !important;
}
.billingPage table .status {
	border-radius: 20px;
	padding: 5px 10px;
	font-size: 12px;
	font-weight: 500;
	/* font-family: "Exo"; */
	border: 1px solid #fff;
	text-align: center;
}
.billingPage table .status.completed {
	background-color: #006100;
	border: 1px solid #00ff00;
	color: #00ff00;
}
.billingPage table {
	margin: 0;
}
.billingPage .table-responsive {
	border: 1px solid #535353;
	border-radius: 20px;
	background-image: linear-gradient(
		-32deg,
		#0f0f0f 0%,
		#1a1a1a 98%,
		#1a1a1a 100%
	);
}
.dashboardPage.billingPage .addCreditMain {
	padding: 85px 30px;
}
.color-blue {
	color: #00e1f5;
}
.color-pink {
	color: #ff00c0;
}
.color-yellow {
	color: #fff600;
}
.color-green {
	color: #00ff00;
}
.color-orange {
	color: #ffa200;
}
.color-red {
	color: #ff0056;
}
.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: #1f1f1f !important;
}

.dashboardPage .chartCard text tspan {
	font-size: 12px;
	color: #afafaf;
	font-weight: 500;
}
.dashboardPage .chartCard.chart1 {
	background-image: linear-gradient(128deg, #022125 0%, #0c0c0c 100%);
}

.dashboardPage .chartCard {
	padding: 20px 30px;
	border-radius: 16px;
	border-radius: 22px;
	background-image: linear-gradient(
		-32deg,
		#0f0f0f 0%,
		#1a1a1a 98%,
		#1a1a1a 100%
	);
	border: 1px solid #535353;
	min-height: 241.6px;
	/* height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.d-flex {
	display: flex;
}
.fd-column {
	flex-direction: column;
}
.jc-sb {
	justify-content: space-between;
}
.dashboardPage .chartCard .title {
	font-size: 15px;
	color: #ffffff;
	font-weight: 500;
	font-family: "Exo";
	/* margin-bottom: 20px; */
	min-height: 45px;
}
.dashboardPage .chartCard .title img {
	margin-right: 5px;
}
.dashboardPage .chartCard .creditM {
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	color: #afafaf;
	font-weight: 500;
	font-family: "Exo";
}
.dashboardPage .chartCard .ramImg img {
	max-width: 130px;
	/* width: 100%; */
	width: calc(100% - 113px);
}
.dashboardPage .chartCard .mbText {
	font-size: 37px;
	line-height: 70px;
	color: #ffffff;
	font-weight: 700;
	line-height: 1;
	margin-left: 12px;
}
.dashboardPage .chartCard .rambar {
	background-image: linear-gradient(270deg, #18dffb 0%, #1f75e3 100%);
	height: 5px;
	margin-top: 16px;
	margin-bottom: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
}
.dashboardPage .chartCard .cpuChartMain .rambar1 {
	background-image: linear-gradient(270deg, #18dffb 0%, #1f75e3 100%);
	height: 5px;
	margin-top: 16px;
	margin-bottom: 20px;
	max-width: 200px;
	margin-left: auto;
	margin-right: auto;
}
.dashboardPage .chartCard .ramImg {
	display: flex;
	align-items: flex-end;
}
.dashboardPage .chartCard .creditM div:first-child {
	margin-right: 20px;
}
.dashboardPage .chartCard .bar {
	max-width: 230px;
	height: 2px;
	background-color: #838382;
	margin: 10px 0;
	position: relative;
}
.dashboardPage .chartCard .bar span {
	position: absolute;
	display: inline-block;
	top: 0;
	right: 0;
	bottom: 0;
	background: var(--mainColor);
	width: 50%;
}

.dashboardPage .chartCard .desc {
	font-size: 11px;
	color: #afafaf;
	font-weight: 500;
	font-family: "Exo";
	margin-top: 10px;
}
.topbar #sidebarToggleTop {
	margin-right: 10px;
}
.topbar #sidebarToggleTop i {
	color: var(--mainColor);
}
@media (max-width: 576px) {
	.dashboardPage .portfolioMain .balanceMain {
		max-width: 100%;
		margin-bottom: 1rem;
	}
	.dashboardPage .dashBannerMain .head {
		font-size: 32px;
	}
	.dashboardPage .currentCreditMain .price {
		font-size: 40px;
	}
}

.multi-graph {
	width: 200px;
	height: 100px;
	position: relative;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
}
.multi-graph:before {
	content: "";
	width: 200px;
	height: 100px;
	border: 50px solid rgba(0, 0, 0, 0.15);
	border-bottom: none;
	position: absolute;
	box-sizing: border-box;
	transform-origin: 50% 0%;
	border-radius: 300px 300px 0 0;
	left: 0;
	top: 0;
}
.multi-graph .graph {
	width: 200px;
	height: 100px;
	border: 34px solid var(--fill);
	border-top: none;
	position: absolute;
	transform-origin: 50% 0% 0;
	border-radius: 0 0 200px 200px;
	left: 0;
	top: 100%;
	z-index: 5;
	animation: 1s fillGraphAnimation ease-in;
	transform: rotate(calc(1deg * (var(--percentage) * 1.8)));
	box-sizing: border-box;
	cursor: pointer;
}
.multi-graph .graph:after {
	content: attr(data-name) " " counter(varible) "%";
	counter-reset: varible var(--percentage);
	background: var(--fill);
	box-sizing: border-box;
	border-radius: 2px;
	color: #fff;
	font-weight: 200;
	font-size: 12px;
	height: 20px;
	padding: 3px 5px;
	top: 0px;
	position: absolute;
	left: 0;
	transform: rotate(calc(-1deg * var(--percentage) * 1.8)) translate(-30px, 0px);
	transition: 0.2s ease-in;
	transform-origin: 0 50% 0;
	opacity: 0;
}
.multi-graph .graph:hover {
	opacity: 0.8;
}
.multi-graph .graph:hover:after {
	opacity: 1;
	left: 30px;
}
.custom-file-upload {
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	background-color: #18e1fd;
	color: white;
	border: none;
	border-radius: 4px;
}

.custom-file-upload:hover {
	background-color: #188fa0;
}
@keyframes fillAnimation {
	0% {
		transform: rotate(-45deg);
	}
	50% {
		transform: rotate(135deg);
	}
}
@keyframes fillGraphAnimation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
	.dashboardPage .chartCard .mbText {
		font-size: 26px;
	}
	.dashboardPage .chartCard .ramImg img {
		width: calc(100% - 96.8px);
	}
	.multi-graph {
		width: 170px;
		height: 85px;
	}
	.multi-graph:before {
		width: 170px;
		height: 85px;
	}
	.multi-graph .graph {
		width: 170px;
		height: 85px;
	}
}

@media screen and (max-width: 992px) {
	.dashboardPage .dashBannerMain.dashBannerMain2 {
		text-align: center;
	}
	.dashboardPage .dashBannerMain2 .head {
		margin: auto;
	}
	.dashboardPage .dashBannerMain2 .title {
		margin-top: 30px;
	}
}
