
@font-face {
  font-family: poppins;
  src: url(./assets/font/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(./assets/font/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: poppinsBold;
  src: url(./assets/font/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: gilroyLight;
  src: url(./assets/font/gilroy/Gilroy-Regular.ttf);
}
@font-face {
  font-family: exo;
  src: url(./assets/font/Exo/Exo-VariableFont_wght.ttf);
}
:root {
  --whiteColor:#fff;
  --titleColor:#21225f;
  --mainColor: #18e1fd;
  --grayColor: #808080;
  --lightGrayColor: rgba(42, 43, 46, 0.6);
  --lightGrayColor04: rgba(71, 74, 79,0.4);
  --lightGrayColorHome: #F7F7FD;
  --textDarkColor: #2B2B2B;
  --bgGradient: linear-gradient(-45deg, #1f1f1f 0.7%, #131313 92.36%);
  --themeBg: #16161A;
  --icoColor:#5C5E62;
  --transition: all 0.3s;
  --grayText:#898989;
}
a {
  color: var(--mainColor);
}

.navbar .navbar-nav {
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.navbar .navbar-nav .connectWalletBtn span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--mainColor);
  display: inline-block;
  box-shadow: 0px 0px 8px 3px rgba(24,225,253,0.75);
  margin-right: 10px;
}
.navbar .socialMain {
  display: flex;
  align-items: center;
}
.navbar .socialMain a {
  display: inline-block;
  margin-right: 10px;
  transition: all 0.3s;
}
.navbar .socialMain a:hover {
  transform: scale(1.2);
  transition: all 0.3s;
}
.navbar .socialMain a img{
  height: 13px;
}
.navbar .connectWalletBtn {
  padding: 10px 25px;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  /* background-image: linear-gradient(120deg, #002025 0%, #076d7b 42%, #18e1fd 100%); */
  background-image: linear-gradient(-30deg, #18e1fd 0%, #000000 100%);
  border: 2px solid #292929;
}
.navbar .connectWalletBtn:hover {
  box-shadow: 0px 0px 10px 7px rgba(6,144,163,0.65);
}
.themeBtn {
  padding: 10px 25px;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  /* background-image: linear-gradient(120deg, #002025 0%, #076d7b 42%, #18e1fd 100%); */
  background-image: linear-gradient(-30deg, #18e1fd 0%, #000000 100%);
  border: 2px solid #292929;
}
.themeBtn:hover {
  box-shadow: 0px 0px 10px 7px rgba(6,144,163,0.65);
}

.is-sticky+#enrollment {
  margin-top: 100px;
}
.navbar {
  background-color: #131313;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}
.navbar .logoDiv {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.navbar-area.dark {
  background-color: var(--themeBg);
}
.navbar-area.dark .navbar .navbar-nav .nav-item a {
  color: #fff;
}
.navbar-area .navbar .navbar-nav .nav-item a {
  color: #2b2b2b;
}
.navbar .navbar-nav .nav-item a {
  font-family: poppinsMedium, serif;
  font-size: 14px;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
}
.navbar .navbar-nav .dropdown-menu .dropdown-item:hover i{
  color: var(--themeBg) !important;
}
.navbar .navbar-nav .dropdown-menu .dropdown-item:hover{
  color: var(--themeBg);
} 
.navbar .navbar-nav .dropdown-menu .dropdown-item {
  margin-left: 0 !important;
  margin-right: 0;
  padding: 0 15px;
}
.navbar .navbar-nav .nav-item a:hover, .navbar .navbar-nav .nav-item a:focus, .navbar .navbar-nav .nav-item a.active {
  color: var(--mainColorHome);
}

.navbar .navbar-nav .nav-item .walletIco {
  width: 30px;
  margin-right: 10px;
}

.navbar .navbar-nav .currencyDiv {
  font-family: poppinsBold, serif;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem ;
  border-radius: 6.275px;
  background: var(--lightGrayColor04);
  color: var(--whiteColor);
  box-shadow: 0px 5.22918px 14.64172px 0px rgba(73, 73, 205, 0.05);
  font-size: 13.5px;
}
.navbar .navbar-nav .currencyDiv .price img {
  margin-right: 4px;
}
.jc-sb {
  justify-content: space-between;
}
.sec5 {
  padding: 70px 0;
}
.sec5 .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,
.sec5 .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  display: none;
}
.sec5 .videoImgCard {
  width: 100%;
}
.sec5 .videoImgCard .imgDiv img {
  width: 100%;
  height: auto;
}
.sec5 .swiper {
  z-index: 0;
}
.sec5 .swiper+div {
  z-index: 1000;
}
.sec5 .videoImgCard .title {
  padding: 20px 12px;
  font-size: 18px;
  font-weight: bold;
  min-height: 78px;
  display: flex;align-items: center;
  justify-content: center;
}
.sec5 .videoImgCard .imgDiv {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.sec5 h1 {
  color: #050C26;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.sec5 .autoplay-progress {
  display: none;
}

.sec5 p {
  color: #8A8A8A;
  text-align: center;
}
.contact-form .opinionMain1 > label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}
.contact-form .opinionMain1 .opinionMain {
  display: flex;
}
.contact-form .opinionMain1 .opinionMain .opinion label{
  display: block;
}
.contact-form .opinionMain1 .opinionMain .opinion{
  text-align: center;
  margin-right: 10px;
}

.otpModalMain {
  padding: 10px;
  position: relative;
}
.otpModalMain .closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  font-size: 20px;
}
.otpModalMain .title{
  color: var(--themeBlue);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}
.otpModalMain .desc{
  color: var(--bodyColor);
  margin-bottom: 30px;
  text-align: center;
}
.otpModalMain .otpInputMain {
  display: flex;
  justify-content: center;
}
.otpModalMain .otpInputMain input:not(:empty){
  background-color: #fff;
}
.otpModalMain .otpInputMain input {
  background-color: rgba(255, 255, 255, .1);
  color: var(--titleColor);
  border: 1px solid var(--titleColor);
  border-radius: 7px;
  width: 63px !important;
  height: 70px;
  font-size: 34px !important;
}
.otpModalMain .otpInputMain input:focus {
  border: 1px solid var(--mainColor) !important;
  box-shadow: 0px 0px 0px 3px rgba(4, 165, 94, 0.12);
  outline: none !important;
}
.otpModalMain .teather {
  font-weight: 500;
  margin-bottom: 2px;
}
.otpModalMain .noteTitle {
  color: red;
  font-weight: bold;
}
.otpModalMain .desc1 {
  margin-bottom: 5px;
}
.otpModalMain .note {
  color: red;
  margin-bottom: 15px;
}
.otpModalMain .connectViaMain {
  display: flex;
  align-items: center;
}
.otpModalMain .connectViaMain .themeBtn span{
  font-size: 20px;
  font-weight: bold;
}
.otpModalMain .connectViaMain .themeBtn {
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
}
.otpModalMain .connectViaMain .themeBtn:first-child {
  margin-right: 10px;
}
.color-dark {
  color: #555 !important;
}
.white-color {
  color: #fff !important;
}
.arrowDownIco {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0,-50%);
}
.contact-widget-area {
  background: var(--themeBg);
  min-height: calc( 100vh - 63.2px);
}
.form-group .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.form-group input{
  color: #797979 !important;
}
.form-group input::-webkit-input-placeholder, .form-group input:-ms-input-placeholder, .form-group input::-ms-input-placeholder, .form-group input::-webkit-input-placeholder {
  color: #797979 !important;
}
.form-group svg, .form-group i {
  color: #797979 !important;

}
.form-group .dobPlaceholder{
  padding: 15px ;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  bottom: 0;
  background-color: rgb(49 48 55);
  color: #797979;
}
.sendMailBtn button {
  background: #43C2F2;
  border-radius: 10px;
  padding: 6px 25px;
  font-weight: bold;
  color: white;
}

/*================================
Footer Area
===================================*/
.footer-contact-area {
  background-color: var(--mainColor);
}

.footer-contact-area .section-title h2 {
  color: var(--whiteColor);
}

.footer-contact-area .default-btn {
  background-color: var(--whiteColor);
  color: var(--mainColor);
}

.footer-contact-area .default-btn:hover {
  color: var(--whiteColor);
}

.footer-area {
  /* background-color: var(--titleColor); */
  background-color: #E2E7FF;
  color: var(--textDarkColor);
  padding: 40px 0;
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget .footer-logo {
  margin-bottom: 20px;
}

.footer-widget h3 {
  margin-top: 0;
  font-family: poppinsMedium,serif;
  font-size: 20px;
  margin-bottom: 15px;
  display: block;
  position: relative;
  z-index: 1;
  padding-bottom: 10px;
}


.footer-widget p {
  margin-bottom: 25px;
  font-family: poppinsMedium, serif;
}

.footer-widget .social-link .socialLinks{
  display: flex;
}
.footer-widget .social-link .social-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-family: poppinsMedium,serif;
}
.footer-widget .social-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 15px;

}

.footer-widget .social-link .fb {
  margin-right: 10px;
}

.footer-widget .social-link li.social-title {
  font-size: 18px;
  color: var(--whiteColor);
}

.footer-widget .social-link a:last-child {
  margin-right: 0;
}

.footer-widget .social-link li a {
  display: inline-block;
}

.footer-widget .social-link a i {
  font-size: 15px;
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px;
  color: var(--mainColorHome  );
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
}

.footer-widget .social-link a:hover i {
  color: var(--whiteColor);
  background-color: var(--mainColorHome);
  transition: var(--transition);
}

.footer-widget .footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-list li {
  display: block;
  margin-bottom: 10px;
  /* padding-left: 25px; */
  position: relative;
}


.footer-widget .footer-list li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-list li a {
  color: var(--textDarkColor);
  text-decoration: none;
}

.footer-widget .footer-list li a:hover {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-list li:hover::before {
  color: var(--whiteColor);
}

.footer-widget .footer-list li:hover a {
  color: var(--mainColor);
  letter-spacing: 0.25px;
}

.footer-widget .footer-contact {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-widget .footer-contact li {
  display: block;
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}

.footer-widget .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-contact li i {
  font-size: 18px;
  color: var(--mainColor);
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 1;
}

.footer-widget .footer-contact li .content h4 {
  font-size: var(--fontSize);
  color: var(--whiteColor);
  margin-bottom: 5px;
}

.footer-widget .footer-contact li .content span {
  color: var(--whiteColor);
  margin-bottom: 0;
}

.footer-widget .footer-contact li .content span a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-widget .footer-contact li:hover .content span a {
  color: var(--mainColor);
}
.footer-widget .newletterInput input::placeholder {
  color: #AAA;
}
.footer-widget .newletterInput input {
  width: 100%;
  /* height: 44px; */
  border: none;
  outline: none;
  padding: 10px;
}
.footer-widget .newletterInput button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: var(--mainColorHome);
  color: white;
  flex-shrink: 0;
  border: 2px solid var(--mainColorHome);
}
.footer-widget .newletterInput button:hover {
  color: var(--mainColor);
  background: var(--whiteColor);
}
.footer-widget .newletterInput {
  border-radius: 10px;
  background: var(--White, #FFF);
  box-shadow: 4px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: flex;
  align-items: center;
}

.copyright-area {
  background: #0B090A;
  padding: 15px 0;
  color: #AAA;
}

.copy-right-text .terms a{
  color: #AAA;
  text-decoration: none;
}
.copy-right-text .terms{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.copy-right-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.copy-right-text p a {
  color: var(--mainColor);
  font-weight: 500;
}

.copy-right-text p a:hover {
  color: var(--whiteColor);
}

.homePage .sec1 {
  padding: 50px 0;
  min-height: calc(100vh - 77.2px) ;
  display: flex;
  align-items: center;
}
.homePage .sec1 .content {
  max-width: 490px;
 }
 .homePage .sec1 .content h1 {
   font-family: poppinsBold ,serif;
   font-weight: 600;
   color: #091D7A;
   font-size: 55px;
  }
.homePage .sec1 .content .tagline {
  color: var(--grayColor);
  font-size: 20px;
  margin: 30px 0;
  line-height: 26px;
}
.homePage .sec1 .imgDiv {
  text-align: center;
  max-width: 390px;
  margin: auto;
}
  .ai-center {
  align-items: center;
}
.jc-center {
  justify-content: center;
}
.homePage .homeCardDouble.highPadding {
  padding-top: 70px;
  padding-bottom: 70px;
}
.homePage .homeCardDouble {
  border-radius: 45px;
  background: #E2E7FF;
  position: relative;
  padding: 40px;
}
.homePage .homeCardDouble::before {
  content: '';
  background: var(--mainColorHome);
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: 100%;
  height: 100%;
  border-radius: 55px;
  z-index: -1;
}
.homePage .sec2 {
  background: var(--mainColorHome);
  padding: 40px 0;
  text-align: center;
  font-family: poppinsMedium, serif;
  color: var(--whiteColor);
}
.homePage .sec2 .pCard {
  max-width: 310px;
  margin: auto;
}
.homePage .sec2 img {
  margin-bottom: 10px;
}
.homePage .sec2 p {
  font-size: 18px;
  margin: 0;
}
.homePage .sec2 .heading {
  font-size: 30px;
  margin-bottom: 30px;
}
.homePage .sec3 {
  padding: 60px 0;
}
.homePage .sec3 .heading {
  color: var(--textDarkColor);
  font-size: 45px;
  text-align: center;
  font-family: poppinsBold ,serif;

}
.homePage .sec3 .tagline {
  color: var(--grayColor);
  font-size: 18px;
  text-align: center;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 30px;
}
.homePage .sec3 .mCard {
  border-radius: 30px;
  background: var(--whiteColor);
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  display: flex;
  /* align-items: center; */
  height: 100%;
}
.homePage .sec3 .mCard .iconDiv{
  width: 40px;
  margin-right: 15px;
  align-self: center;
}
.homePage .sec3 .mCard .title {
  font-family: poppinsMedium;
  color: var(--mainColorHome);
  font-size: 20px;
}
.homePage .sec3 .mCard .desc {
  color: var(--grayColor);
}
.homePage .sec4 {
  padding: 60px 0;
}
.homePage .sec4 .innerContainer {
  max-width: 750px;
  margin: auto;
  position: relative;
}
.homePage .sec4 .innerContainer .sNo {
  position: absolute;
  font-family: poppinsBold;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--mainColorHome);
  font-size: 35px;
  color: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(0, -50%);
  left: -120px;
  text-align: center;
}
.homePage .sec4 .innerContainer .imgDiv {
  text-align: center;
}
.homePage .sec4 .innerContainer .imgDiv img{
  mix-blend-mode: multiply;
}
.homePage .sec4 .heading {
  color: var(--textDarkColor);
  font-family: poppinsBold;
  text-align: center;
  font-size: 42px;
  margin-bottom: 40px;
}
.homePage .sec4 .subHeading {
  color: var(--textDarkColor);
  font-family: poppinsBold;
  text-align: center;
  font-size: 35px;
  margin-top: 25px;
  margin-bottom: 5px;
}
.homePage .sec4 .content {
  text-align: center;
  max-width: 400px;
  margin: auto;
}
.homePage .sec4 .tagLine {
  color: var(--grayColor);
  margin-bottom: 20px;
  font-size: 18px;
}
.homePage .sec4 .houseCard {
  background: var(--whiteColor);
  box-shadow: 0px 2.73211px 19.12478px -1.36606px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 10px;
  max-width: 208px;
  margin: auto;
}
.homePage .sec4 .houseCard .imgDiv img {
  max-width: 100%;
  border-radius: 8px;
  
}
.homePage .sec4 .houseCard .contentM {
  padding: 8px 4px ;
}
.homePage .sec4 .houseCard .contentM .title{
  font-size: 11px;
  color: var(--textDarkColor);
  font-family: poppinsBold, serif;
}
.homePage .sec4 .houseCard .contentM .address {
  color: #898989;
  font-size: 8px;
}
.homePage .sec4 .houseCard .contentM .invesRate {
  display: flex;
  align-items: center;
}
.homePage .sec4 .houseCard .contentM .invesRate .ico{
  width: 16px;
  flex-shrink: 0;
}
.homePage .sec4 .houseCard .contentM .invesRate .rate{
  font-size: 8px;
  color: #353535;
  font-family: poppinsBold, serif;
  margin: 0 4px;
}
.homePage .sec4 .houseCard .contentM .invesRate .mInvest {
  color: var(--mainColor);
  font-size: 8px;
}
.homePage .sec4 .redeemRow .col1 {
  max-width: 60%;
}
.homePage .sec4 .redeemRow .col2 {
  max-width: 32%;
  position: absolute;
  right: 19%;
  top: 25%;
}
.max-width-700 {
  max-width: 700px !important;
}
.homePage .sec5 {
  padding: 60px 0;
}
.homePage .sec5 h2{
  color: var(--textDarkColor);
  font-size: 45px;
  font-family: poppinsBold,serif;
}
.homePage .sec5 .desc {
  color: var(--grayColor);
  font-size: 18px;
}
.homePage .sec5 .eqCard img {
  margin-right: 10px;
}
.homePage .sec5 .eqCard .title {
  color: #213279;
  font-family: poppinsBold,serif;
}

.homePage .sec5 .eqCard {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.homePage .sec6 {
  padding: 60px 0;
  background: #d4d4d442;
  text-align: center;
}
.homePage .sec6 h1 {
  color: #0B090A;
  font-family: poppinsBold,serif;
}
.homePage .sec6 .tagline{
  font-size: 20px;
  color: var(--grayColor);
  margin-bottom: 40px;
}
.homePage .sec6 .cCard {
  border-radius: 30px;
  background: var(--whiteColor);
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.03);
  padding: 25px;
  height: 100%;
}
.homePage .sec6 .cCard .title {
  color: var(--textDarkColor);
  font-family: poppinsMedium,serif;
  margin-bottom: 10px;
  font-size: 20px;
}
.homePage .sec6 .cCard .desc {
  color: var(--grayColor);
  margin-bottom: 10px;
}
.homePage .sec6 .cCard .ico {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: var(--mainColorHome);
  box-shadow: drop-shadow(4px 10px 30px rgba(67, 97, 238, 0.40));
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePage .sec6 .communityM {
  max-width: 950px;
  margin: auto;
}
.homePage .sec7 {
  padding: 60px 0;
  text-align: center;
}
.homePage .sec7 h1 {
  color: var(--textDarkColor);
  margin-bottom: 30px;
  font-family: poppinsBold,serif;
}
.homePage .sec7 .desc {
  color: var(--grayColor);
  font-size: 18px;
  margin-bottom: 15px;
}
.homePage .sec7 .will {
  font-family: poppinsBold,serif;
  font-size: 20px;
  margin-bottom: 10px;
}
.homePage .sec7 .socialM a {
  transition: all 0.3s;
}
.homePage .sec7 .socialM a:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}
.homePage .sec7 .socialM {
  max-width: 160px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.homePage .newsLetterSec {
  padding: 60px 0;
}
.homePage .newsLetterSec .newsCard {
  padding: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 41px;
  background: var(--mainColorHome);
  color: var(--whiteColor);
}
.homePage .newsLetterSec .newsCard p {
  margin-bottom: 0;
  max-width: 500px;
}

.homePage .newsLetterSec .newsCard h1 {
  font-size: 42px;
  color: white;
  font-family: poppinsBold,serif;
  
}
.homePage .newsLetterSec .newsCard .emailM input::placeholder {
  color: var(--whiteColor);
}
.homePage .newsLetterSec .newsCard .emailM input {
  background: var(--mainColorHome);
  border-radius: 9px;
  border: 1px solid var(--whiteColor);
  padding: 10px;
  color: white;
}
.homePage .newsLetterSec .newsCard .emailM button {
  max-width: 108px;
  width: 100%;
  background: white;
  border-radius: 6.256px;
  background: var(--whiteColor);
  border: 2px solid var(--whiteColor);
  font-size: 12px;
  padding: 7px;
  color: var(--mainColorHome);
  font-family: poppinsMedium,serif;
  margin-top: 30px;
}

.noData {
  color:#A0AEC0;
  font-family: poppinsMedium;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}

/* signup/ sigin form */
.signup-form a {
  color: var(--mainColorHome);
}
.contact-form.signup-form{
  max-width: 600px;
  margin: auto;
}
.WalletModal .contact-form {
  background-color: var(--lightGrayColor);
}
.modal-body {
  background-color: var(--themeBg);
}
.contact-form {
  margin-bottom: 30px;
  padding: 50px 35px 47px;
  background-color: var(--lightGrayColor);
  /* background-color: var(--lightGrayColorHome); */
}

.contact-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.contact-form .form-group.checkbox-option {
  position: relative;
}

.contact-form .form-group.checkbox-option #chb2 {
  position: absolute;
  top: 6px;
  left: 0;
}

.contact-form .form-group.checkbox-option p {
  padding-left: 25px;
  font-size: 15px;
  color: var(--titleColor);
}

.contact-form .form-group.checkbox-option p a {
  color: var(--mainColor);
}

.contact-form .form-group.checkbox-option p a:hover {
  color: var(--titleColor);
}

.WalletModal .contact-form .form-group .form-control {
  background-color: var(--lightGrayColor04);
  color: #fff !important;
}
.contact-form .form-group .form-control {
  height: 50px;
  color: #797979;
  border: none;
  background-color: var(--lightGrayColor04);
  padding: 15px 15px;
  width: 100%;
  border-radius: 0;
}

.contact-form .form-group .form-control::-webkit-input-placeholder, .contact-form .form-group .form-control:-ms-input-placeholder, .contact-form .form-group .form-control::-ms-input-placeholder, .contact-form .form-group .form-control::-webkit-input-placeholder {
  color: var(--icoColor);
}


.contact-form .form-group .form-control::-webkit-input-placeholder, .contact-form .form-group .form-control:-ms-input-placeholder, .contact-form .form-group .form-control::-ms-input-placeholder, .contact-form .form-group .form-control::placeholder {
  color: var(--icoColor);
}

.contact-form .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.contact-form .form-group textarea.form-control {
  height: auto;
  border-radius: 0;
}

.contact-form .agree-label {
  margin-bottom: 15px;
  position: relative;
}

.contact-form .agree-label #chb1 {
  position: absolute;
  top: 7px;
  left: 0;
}

.contact-form .agree-label label {
  font-weight: 500;
  color: var(--titleColor);
  margin-left: 25px;
}

.contact-form .agree-label label a {
  color: var(--mainColor);
}

.contact-form .agree-label label a:hover {
  color: var(--titleColor);
}

.contact-form .agree-label label a.forget {
  position: absolute;
  right: 0;
}

.contact-form .with-errors {
  float: left;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #f00;
  font-weight: 400;
  display: block;
}

.contact-form .text-danger {
  font-size: 18px;
  margin-top: 15px;
}

.contact-form .default-btn {
  border: 0;
  outline: none;
}
.mb-45 {
  margin-bottom: 45px;
}
.section-title {
  position: relative;
  z-index: 1;
}
.section-title h2 {
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  color: var(--whiteColor);
}
.pb-70 {
  padding-bottom: 70px;
}
/* signup/ sigin form */


/* wallet modal */
.WalletModal {
  color: var(--mainColor);
  backdrop-filter: blur(8px);
}
.WalletModal h1{
  color: var(--themeBlue);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}
.WalletModal .walletRow{
  display: flex;
  justify-content: center;
}
.WalletModal .metamask{
 margin-right: 15px;
}
.WalletModal .walletImg .img-fluid{
  height: 100%;
}
.WalletModal .walletImg{
  width: 230px;
  margin: auto;
  margin-bottom: 30px;
  height: 120px;
  text-align: center;
}
.WalletModal .walletConnect .walletImg{
  width: 226px;
  margin: auto;
  margin-bottom: 30px;
}
.WalletModal .btnDiv {
  text-align: center;
  margin-bottom: 30px;
}

.WalletModal .helpDv{
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;
}
.WalletModal .helpDv img{
  width: 20px;
  margin-right: 5px;
}
.WalletModal .closeIcon{
  position: absolute;
  top: 10px;
  right: 12px;
}
.WalletModal .closeIcon i{
  font-size: 25px;
  color: #707070;
  cursor: pointer;
}
.requestTable .dropdown-toggle::after {
  display: none;
}

.addCreModal input {
  background-image: linear-gradient(-32deg, #0f0f0f 0%, #1a1a1a 98%, #1a1a1a 100%);
  /*border: 1px solid #535353;*/
  color: #fff;
}
.addCreModal input:focus {
  color: #fff;
  box-shadow: none;
  border-color: var(--mainColor);
}

/* wallet modal */
@media screen and (max-width:992px) {
  .navbar .navbar-nav {
    align-items: flex-start;
    /* margin: 10px 0; */
  }
  .homePage .newsLetterSec .newsCard .content{
    margin-bottom: 20px;
  }
  .homePage .newsLetterSec .newsCard {
    flex-wrap: wrap;
  }
  .homePage .sec4 .innerContainer .sNo {
    left: 0;
    top: -75px;
    transform: unset;
  }
  .homePage .sec4 .heading {
    margin-bottom: 80px;
  }
}

@media screen and (max-width:768px) {
  .homePage .sec1 .content {
    margin-bottom: 20px;
  }
  .homePage .sec2 .pCard {
    margin-bottom: 20px;
  }
  .homePage .sec4 .houseCard {
    margin-bottom: 20px;
  }
  .navbar .navbar-nav .currencyDiv {
    margin-bottom: 10px;
  }
  .navbar .navbar-nav {
    flex-wrap: wrap;
  }
  .contact-form {
    padding: 30px 20px 27px;
  }
  .contact-form .agree-label label a.forget {
    position: inherit;
  }
  .navbar .navbar-nav {
    margin: 0;
    padding: 20px 0;
  }
 
}
@media screen and (min-width:576px) and (max-width:768) {
  .contact-form .agree-label label a.forget {
    position: absolute;
  }
}
@media screen and (max-width:576px) {
  .homePage .homeCardDouble::before {
    left: -8px;
    bottom: -8px;
  }
  .homePage .sec4 .redeemRow .col2 {
    max-width: 36%;
    right: 9%;
    top: 30%;
  }
  .homePage .sec4 .redeemRow .col1 {
    max-width: 70%;
  }
  .homePage .homeCardDouble {
    padding: 20px;
  }
  .homePage .sec1 .content h1 {
    font-size: 40px;
  }
  .homePage .sec1 .content .tagline {
    font-size: 18px;
  }
  .homePage .sec3 .heading {
    font-size: 35px;
  }
  .homePage .sec4 .heading {
    font-size: 35px;
  }
  .homePage .sec4 .subHeading {
    font-size: 30px;
  }
  .homePage .sec5 h2 {
    font-size: 35px;
  }
  .homePage .sec5 .desc {
    font-size: 16px;
  }
  .homePage .sec6 h1 {
    font-size: 35px;
  }
  .homePage .sec6 .tagline {
    font-size: 18px;
  }
  .homePage .sec7 .desc {
    font-size: 16px;
  }
  .homePage .newsLetterSec .newsCard h1 {
    font-size: 35px;
  }
  .homePage .newsLetterSec .newsCard {
    padding: 40px 25px;
  }
  .footer-widget {
    padding-left: 0 !important;
  }
  .copy-right-text .terms {
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
  }
  .copy-right-text {
    justify-content: center;
  }
  .copy-right-text .terms a {
    margin: 0 !important;
  }
}