/* global */
*, ::after, ::before {
   box-sizing: border-box;
}
html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
   display: block;
}
body {
   margin: 0;
   font-family: exo, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-size: 1rem;
   line-height: 1.5;
   color: #858796;
   text-align: left;
   background-color: #fff;
   transition:all 0.3s;
}
[tabindex="-1"]:focus:not(:focus-visible) {
   outline: 0!important;
}

h1, h2, h3, h4, h5, h6 {
   margin-top: 0;
   margin-bottom: .5rem;
}
p {
   margin-top: 0;
   margin-bottom: 1rem;
}
abbr[data-original-title], abbr[title] {
   text-decoration: underline;
   -webkit-text-decoration: underline dotted;
   text-decoration: underline dotted;
   cursor: help;
   border-bottom: 0;
   -webkit-text-decoration-skip-ink: none;
   text-decoration-skip-ink: none;
}
address {
   margin-bottom: 1rem;
   font-style: normal;
   line-height: inherit;
}
dl, ol, ul {
   margin-top: 0;
   margin-bottom: 1rem;
}
ol ol, ol ul, ul ol, ul ul {
   margin-bottom: 0;
}
dt {
   font-weight: 700;
}
dd {
   margin-bottom: .5rem;
   margin-left: 0;
}
blockquote {
   margin: 0 0 1rem;
}
b, strong {
   font-weight: bolder;
}
small {
   font-size: 80%}
sub, sup {
   position: relative;
   font-size: 75%;
   line-height: 0;
   vertical-align: baseline;
}
sub {
   bottom: -.25em;
}
sup {
   top: -.5em;
}
img {
   vertical-align: middle;
   border-style: none;
}
svg {
   overflow: hidden;
   vertical-align: middle;
}
table {
   border-collapse: collapse;
}
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 5px;
	/* background-color: #F5F5F5; */
}

.table-responsive::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
#wrapper {
   display: flex;
}
#wrapper #content-wrapper {
   background: linear-gradient(45deg, #0c0c0c 60%, #202020 74%);
   width: 100%;
   overflow-x: hidden;
}
#wrapper #content-wrapper #content {
   flex: 1 0 auto;
}

.d-flex {
   display: flex!important;
}
.flex-column {
   flex-direction: column!important;
}
.align-items-center {
   align-items: center!important;
}
.text-xs {
   font-size: .7rem;
}
.font-weight-bold {
   font-weight: 700!important;
}
.text-primary {
   color: #4e73df!important;
}
.text-gray-800 {
   color: #5a5c69!important;
}
.text-gray-300 {
   color: #dddfeb!important;
}
.dropdown .dropdown-menu {
   font-size: .85rem;
}
.dropdown .dropdown-menu .dropdown-header {
   font-weight: 800;
   font-size: .65rem;
   color: #b7b9cc;
}
.dropdown.no-arrow .dropdown-toggle::after {
   display: none;
}

.btn-circle {
   border-radius: 100%;
   height: 2.5rem;
   width: 2.5rem;
   font-size: 1rem;
   display: inline-flex;
   align-items: center;
   justify-content: center;
}
html {
   position: relative;
   min-height: 100%}
body {
   height: 100%}
a:focus {
   outline: 0;
}

.scroll-to-top {
   position: fixed;
   right: 1rem;
   bottom: 1rem;
   display: none;
   width: 2.75rem;
   height: 2.75rem;
   text-align: center;
   color: #fff;
   background: rgba(90, 92, 105, .5);
   line-height: 46px;
}
.scroll-to-top:focus, .scroll-to-top:hover {
   color: #fff;
}
.scroll-to-top:hover {
   background: #5a5c69;
}
.scroll-to-top i {
   font-weight: 800;
}
@-webkit-keyframes growIn {
   0% {
   transform: scale(.9);
   opacity: 0;
}
100% {
   transform: scale(1);
   opacity: 1;
}
}@keyframes growIn {
   0% {
   transform: scale(.9);
   opacity: 0;
}
100% {
   transform: scale(1);
   opacity: 1;
}
}.animated--grow-in, .sidebar .nav-item .collapse {
   -webkit-animation-name: growIn;
   animation-name: growIn;
   -webkit-animation-duration: .2s;
   animation-duration: .2s;
   -webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
   animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
}
@-webkit-keyframes fadeIn {
   0% {
   opacity: 0;
}
100% {
   opacity: 1;
}
}@keyframes fadeIn {
   0% {
   opacity: 0;
}
100% {
   opacity: 1;
}
}.animated--fade-in {
   -webkit-animation-name: fadeIn;
   animation-name: fadeIn;
   -webkit-animation-duration: .2s;
   animation-duration: .2s;
   -webkit-animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
   animation-timing-function: opacity cubic-bezier(0, 1, .4, 1);
}
.text-gray-400 {
   color: #d1d3e2!important;
}
.icon-circle {
   height: 2.5rem;
   width: 2.5rem;
   border-radius: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
/* global end */


/* sidebar and navbar css */

.sidebar .nav-item.dropdown .dropdown-toggle::after, .topbar .nav-item.dropdown .dropdown-toggle::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105';
    font-family: 'Font Awesome 5 Free'}
 .sidebar .nav-item.dropdown.show .dropdown-toggle::after, .topbar .nav-item.dropdown.show .dropdown-toggle::after {
    content: '\f107'}
 .sidebar .nav-item .nav-link, .topbar .nav-item .nav-link {
    position: relative;
 }
 .sidebar .nav-item .nav-link .badge-counter, .topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    transform: scale(.7);
    transform-origin: top right;
    right: .25rem;
    margin-top: -.25rem;
 }
.sidebar .nav-item .nav-link .img-profile, .topbar .nav-item .nav-link .img-profile {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
}
.sidebar .nav-item .nav-link .img-profile img, .topbar .nav-item .nav-link .img-profile img {
   width: 100%;
   min-height: 100%;
   height: auto;
   object-fit: cover;
}
 .topbar {
    height: 4.375rem;
 }
 .topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
 }
 .topbar #sidebarToggleTop:hover {
    background-color: #eaecf4;
 }
 .topbar #sidebarToggleTop:active {
    background-color: #dddfeb;
 }
.topbar .walletBtn button {
   background: #43C2F2;
   border-radius: 10px;
   padding: 6px 25px;
   font-weight: bold;
   color: white;
}
.topbar .heading {
   color: var(--whiteColor);
   font-family: poppinsBold;
   font-size: 30px;
   font-weight: 600;
}
 .topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
 }

 .topbar .nav-item .nav-link {
    /* height: 4.375rem; */
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem !important;
    border-radius: 6.275px;
   background: var(--lightGrayColor04);
   color: var(--whiteColor) !important;
   box-shadow: 0px 5.22918px 14.64172px 0px rgba(73, 73, 205, 0.05);
   
 }
 .topbar .nav-item .nav-link:focus {
    outline: 0;
 }
 .topbar .nav-item:focus {
    outline: 0;
 }
 .topbar .dropdown {
    position: static;
 }
 .topbar .dropdown .dropdown-menu {
    width: calc(100% - 1.5rem);
    /* right: .75rem; */
 }
 .topbar .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden;
 }
 .topbar .dropdown-list .dropdown-header {
    background-color: #4e73df;
    border: 1px solid #4e73df;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff;
 }
 .topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
 }
 .topbar .dropdown-list .dropdown-item .dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
 }
 .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
 }
 .topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
    background-color: #eaecf4;
    height: .75rem;
    width: .75rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: .125rem solid #fff;
 }
 .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 10rem;
 }
 .topbar .dropdown-list .dropdown-item:active {
    background-color: #eaecf4;
    color: #3a3b45;
 }
 @media (min-width:576px) {
    .topbar .dropdown {
    position: relative;
 }
 .topbar .dropdown .dropdown-menu {
    width: auto;
    /* right: 0; */
 }
 .topbar .dropdown-list {
    width: 20rem!important;
 }
 .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
 }

 }.topbar.navbar-dark .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, .8);
 }
 .topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
 }
 .topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
    color: #fff;
 }
 .topbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #d1d3e2;
 }
 .topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: #b7b9cc;
 }
 .topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
    color: #858796;
 }
 .sidebar {
    width: 6.5rem;
    min-height: 100vh;
    background: var(--bgGradient);
 }
 .sidebar .nav-item {
    position: relative;
 }
 .sidebar .nav-item:last-child {
    margin-bottom: 1rem;
 }
 .sidebar .nav-item .nav-link {
    text-align: center;
    padding: .6rem 1rem;
    width: 6.5rem;
 }
 .sidebar .nav-item .nav-link span {
    font-size: .65rem;
    display: block;
 }

 .sidebar .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
 }
 .sidebar .nav-item .collapse .collapse-inner {
    border-radius: .35rem;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    background-color: linear-gradient(-32deg, #0f0f0f 0%, #1a1a1a 98%, #1a1a1a 100%) !important;
    border-radius: 12px !important;
 }
 .sidebar .nav-item .collapsing {
    display: none;
    transition: none;
 }
 .sidebar .nav-item .collapse .collapse-inner, .sidebar .nav-item .collapsing .collapse-inner {
    padding: .5rem 0;
    min-width: 10rem;
    font-size: .85rem;
    margin: 0 0 1rem 0;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-header, .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: .65rem;
    color: #b7b9cc;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
   background-color: transparent !important;
 }
 .sidebar .nav-item .collapse .collapse-inner a:hover, .sidebar .nav-item .collapsing .collapse-inner a:hover {
   color: #fff;
 }
 .sidebar .nav-item .collapse .collapse-inner a, .sidebar .nav-item .collapsing .collapse-inner a {
   color: rgba(255, 255, 255, .7);
   text-decoration: none;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-item, .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    display: block;
    color: rgba(255, 255, 255, .7);
    text-decoration: none;
    border-radius: .35rem;
    white-space: nowrap;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-item:active, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
    background-color: #dddfeb;
 }
 .sidebar .nav-item .collapse .collapse-inner .collapse-item.active, .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
    color: #4e73df;
    font-weight: 700;
 }
 .sidebar #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
 }
 .sidebar #sidebarToggle::after {
    font-weight: 900;
    content: '\f104';
    font-family: 'Font Awesome 5 Free';
    margin-right: .1rem;
 }
 .sidebar #sidebarToggle:hover {
    text-decoration: none;
 }
 .sidebar #sidebarToggle:focus {
    outline: 0;
 }
 .sidebar.toggled {
    width: 0!important;
    overflow: hidden;
 }
 .sidebar.toggled #sidebarToggle::after {
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    margin-left: .25rem;
 }
 .sidebar.toggled .sidebar-card {
    display: none;
 }
 .sidebar .sidebar-brand .mini {
   display: inline;
}
 .sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem ;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;
 }
 .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
 }
 .sidebar .sidebar-brand .sidebar-brand-text {
    display: none;
 }
 .sidebar hr.sidebar-divider {
    margin: 0 1rem 1rem;
 }
 .sidebar .sidebar-heading {
    text-align: center;
    padding: 0 1rem;
    font-weight: 800;
    font-size: .65rem;
 }
 .sidebar .sidebar-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .875rem;
    border-radius: .35rem;
    color: rgba(255, 255, 255, .8);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, .1);
 }
 .sidebar .sidebar-card .sidebar-card-illustration {
    height: 3rem;
    display: block;
 }
 .sidebar .sidebar-card .sidebar-card-title {
    font-weight: 700;
 }
 .sidebar .sidebar-card p {
    font-size: .75rem;
    color: rgba(255, 255, 255, .5);
 }

 @media (min-width:768px) {
   #wrapper #content-wrapper #content .container-fluid {
      padding-left: 30px;
      padding-right: 30px;
   }
   .topbar .heading {
      margin-right: 15px;
   }
    .sidebar {
    width: 16rem!important;
 }
 .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
 }
 .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
 }
 .sidebar .nav-item .collapsing {
    display: block;
    transition: height .15s ease;
 }
 .sidebar .nav-item .collapse, .sidebar .nav-item .collapsing {
    margin: 0 1rem;
 }
 .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    /* padding: 1rem; */
    width: 14rem;
 }
 .sidebar .nav-item .nav-link i {
    font-size: .85rem;
    margin-right: .25rem;
 }
 .sidebar .nav-item .nav-link span {
    font-size: .85rem;
    display: inline;
    /* margin-left: .6rem; */
 }
 .sidebar .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f107';
    font-family: 'Font Awesome 5 Free'}
 .sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: '\f105'}
 .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
 }
 .sidebar .sidebar-brand .mini {
    display: none;
 }
 .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
 }
 .sidebar .sidebar-heading {
    text-align: left;
 }
 .sidebar.toggled {
    overflow: visible;
    width: 6.5rem!important;
 }
 .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
    animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
 }
 .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    border-radius: .35rem;
 }
 .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
 }
 .sidebar.toggled .nav-item .collapse, .sidebar.toggled .nav-item .collapsing {
    margin: 0;
 }
 .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
 }
 .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: .75rem 1rem;
    width: 6.5rem;
    
    color: #8F9FB4;
    margin: auto;
    width: 90%;
    border-radius: 10px;
 }
 .sidebar.toggled .nav-item .nav-link span {
    font-size: .65rem;
    display: block;
 }
 .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
 }
 .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
    display: none;
 }
 .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
 }
 .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
 }
 .sidebar.toggled .sidebar-heading {
    text-align: center;
 }
 .d-lg-none {
   display: none !important;
   }

 }
 @media (max-width:992px) {
   .topbar {
      height: auto;
      margin-bottom: 0 !important;
   }
 }
 @media (max-width:768px) {
   .sidebar-dark .nav-item .nav-link {
      flex-direction: column;
   }
   .sidebar.inactive {
      display: none;
   }
   .sidebar {
      display: none;
   }
   .d-768-none {
      display: none;
   }
   .topbar .nav-item .nav-link {
      background: transparent !important;
      margin: 0 !important;
      padding-left: 0 !important;
    }
   .topbar .dropdown-menu {
      background: transparent;
   }
   #wrapper #content-wrapper #content {
      padding-top: 70px;
   }
   .topbar {
      position: absolute;
    top: 0;
    width: 100%;
    backdrop-filter: blur(0px);
    z-index: 100;
   }
   
 }
 .sidebar-light .sidebar-brand {
    color: #6e707e;
 }
 .sidebar-light hr.sidebar-divider {
    border-top: 1px solid #eaecf4;
 }
 .sidebar-light .sidebar-heading {
    color: #b7b9cc;
 }
 .sidebar-light .nav-item .nav-link {
    color: #858796;
 }
 .sidebar-light .nav-item .nav-link i {
    color: #d1d3e2;
 }
 .sidebar-light .nav-item .nav-link:active, .sidebar-light .nav-item .nav-link:focus, .sidebar-light .nav-item .nav-link:hover {
    color: #6e707e;
 }
 .sidebar-light .nav-item .nav-link:active i, .sidebar-light .nav-item .nav-link:focus i, .sidebar-light .nav-item .nav-link:hover i {
    color: #6e707e;
 }
 .sidebar-light .nav-item .nav-link[data-toggle=collapse]::after {
    color: #b7b9cc;
 }
 .sidebar-light .nav-item.active .nav-link {
    color: #6e707e;
 }
 .sidebar-light .nav-item.active .nav-link i {
    color: #6e707e;
 }
 .sidebar-light #sidebarToggle {
    background-color: #eaecf4;
 }
 .sidebar-light #sidebarToggle::after {
    color: #b7b9cc;
 }
 .sidebar-light #sidebarToggle:hover {
    background-color: #dddfeb;
 }
 .sidebar-dark .sidebar-brand {
    color: #8F9FB4;
 }
 .sidebar-dark hr.sidebar-divider {
    border-top: 1px solid rgba(255, 255, 255, .15);
 }
 .sidebar-dark .sidebar-heading {
    color: rgba(255, 255, 255, .4);
 }
 .sidebar-dark .nav-item .nav-link {
    color: rgba(255, 255, 255, .7);
    font-size: 12px;
    /* font-family: gilroyLight, serif; */
    margin: auto;
    width: 90%;
    border-radius: 25px;
    display: flex;
    align-items: center;
   }
   .sidebar-dark .nav-item .nav-link.active {
      border: 1px solid #00a0c4;
   }
 .sidebar-Link.active .icon svg, .sidebar-Link.active .icon svg path {
   fill: white;
 }
 .w-max {
   width: max-content;
 }
 .sidebar-Link.active .icon {
   /* background: var(--mainColor); */
   /* background: var(--Gray, rgb(71, 74, 79)); */
 }
 .sidebar-Link.active {
   color: #fff !important;
   /* background: #FFF;
   box-shadow: 0px 5px 10px 0px rgba(0, 65, 125, 0.12); */
}
 .sidebar-dark .nav-item .nav-link .icon {
   height: 30px;
   width: 30px;
   display: inline-block;
   border-radius: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 0;
 }
 .sidebar-dark .nav-item .nav-link i {
    color: #8F9FB4;
 }
 .sidebar-dark .nav-item .nav-link img {
    color: #8F9FB4;
    width: 16px;
 }
 /* .sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
    color: #fff;
    background-image: linear-gradient(to right, rgb(255 0 247), rgb(82 12 90));

 } */
 .sidebar-dark .nav-item .nav-link:active i, .sidebar-dark .nav-item .nav-link:focus i, .sidebar-dark .nav-item .nav-link:hover i {
    color: #fff;
 }
 .sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
    color: rgba(255, 255, 255, .5);
 }
 .sidebar-dark .nav-item.active .nav-link {
    color: #8F9FB4;

    background-image: linear-gradient(to right, rgb(255 0 247), rgb(82 12 90));
    margin: auto;
    width: 90%;
    color: #F5F8FD;
    border-radius: 10px;
 }
 .sidebar-dark .nav-item.active .nav-link i {
    color: #8F9FB4;
 }
 .sidebar-dark #sidebarToggle {
    background-color: rgba(255, 255, 255, .2);
 }
 .sidebar-dark #sidebarToggle::after {
    color: rgba(255, 255, 255, .5);
 }
 .sidebar-dark #sidebarToggle:hover {
    background-color: rgba(255, 255, 255, .25);
 }
 .sidebar-dark.toggled #sidebarToggle::after {
    color: rgba(255, 255, 255, .5);
 }
 .fw-500{
    font-weight: 500;
 }
 .theme-bg-color{
    background-color: #F6F7FB !important;
 }
 .card {
   background-color: var(--lightGrayColor);
 }
 .search-btn{
    background-color: #F6F7FB !important;
    border-color: #F6F7FB !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important; 
 }
 .w-15{
     width: 15px;
 }
 .profile-name{
   color: var(--whiteColor);
   font-family: poppinsBold;
   font-size: 13.5px;
   display: block;
 }
 .status{
     font-size: 12px;
     color: gray;
     font-weight: 600;
 }
 .profile-div{
     line-height: 1;
 }
 .w-9{
     width: 10px;
 }
 .zi-1{
    z-index: 1;
 }
 .logout-li{
     position: absolute;
     bottom: 30px;
 }
 .logout-div{
     display: flex !important;
     flex-direction: row !important;
     padding-left: 25px;
 }
 .logout-div .img-profile{
     width: 2rem;
     height: 2rem;
     margin-right: 10px;
 }
 .text-align-left{
    text-align: left !important;
 }
 .pb-100{
    padding-bottom: 100px !important;
 }
 .jc-center{
    justify-content: center;
 }
 .text-right {
   text-align: right;
 }
 .display-block {
   display: block;
 }


 @media(max-width:767px){
    .logout-div{
        display: flex !important;
        flex-direction: column !important;
    }
    .logout-div .img-profile{
        margin: auto;
    }
 }

 .seekoLoader {
   position: fixed;
   top: 0;
   -webkit-backdrop-filter: blur(2px);
   backdrop-filter: blur(2px);
   width: 100vw;
    left: 0;
   height: 100vh;
   background: rgba(0,0,0,.2);
   z-index: 1111;
}
.seekoLoader .loader {
       /*width: 100%;*/
       max-width: 250px;
       padding: 40px 0;
       text-align: center;
       -webkit-transform: translate(-50%,-50%);
       transform: translate(-50%,-50%);
       top: 50%;
       left: 50%;
       /*background: #fff;*/
       border-radius: 20px;
       position: fixed;
   }

  
   .seekoLoader img {
       width: 90px;
       animation: beat .4s infinite alternate;
   }
       /* Heart beat animation */
       @keyframes beat{
         to { transform: scale(1.3); 
         }
     }
.spinloader {
   width: 2rem;
   height: 2rem;
   border: 5px solid #f3f3f3;
   border-top: 6px solid var(--mainColor);
   border-radius: 100%;
   margin: auto;
   visibility: visible;
   animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.custom-toast {
    background-color: #131313 !important; /* Change background color */
    border-top: 3px solid var(--mainColor);
    border-radius: 100%;
    /*border-top: 4px solid #FFD700 !important; !* Add top border with your desired color *!*/
}

.custom-toast-body {
    color: white; /* Change text color if needed */
}