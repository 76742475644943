@font-face {
    font-family: "flaticon";
    src: url("../font/flaticonbbd1.ttf?2cc9ccf9b099e360eeaaafcdb8edcaba") format("truetype"),
        /* url("flaticonbbd1.woff?2cc9ccf9b099e360eeaaafcdb8edcaba") format("woff"),
        url("flaticonbbd1.woff2?2cc9ccf9b099e360eeaaafcdb8edcaba") format("woff2"),
        url("flaticonbbd1.eot?2cc9ccf9b099e360eeaaafcdb8edcaba#iefix") format("embedded-opentype"),
        url("flaticonbbd1.html?2cc9ccf9b099e360eeaaafcdb8edcaba#flaticon") format("svg"); */
        }

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-studying:before {
    content: "\f101";
}
.flaticon-corporate:before {
    content: "\f102";
}
.flaticon-online-course:before {
    content: "\f103";
}
.flaticon-customer-satisfaction:before {
    content: "\f104";
}
.flaticon-platform:before {
    content: "\f105";
}
.flaticon-list:before {
    content: "\f106";
}
.flaticon-mail-inbox-app:before {
    content: "\f107";
}
.flaticon-effective:before {
    content: "\f108";
}
.flaticon-chevron:before {
    content: "\f109";
}
.flaticon-online-course-1:before {
    content: "\f10a";
}
.flaticon-reading-book:before {
    content: "\f10b";
}
.flaticon-camera:before {
    content: "\f10c";
}
.flaticon-heart-beat:before {
    content: "\f10d";
}
.flaticon-left-arrow:before {
    content: "\f10e";
}
.flaticon-play-button-arrowhead:before {
    content: "\f10f";
}
.flaticon-wellness:before {
    content: "\f110";
}
.flaticon-web-development:before {
    content: "\f111";
}
.flaticon-discount:before {
    content: "\f112";
}
.flaticon-design:before {
    content: "\f113";
}
.flaticon-folder:before {
    content: "\f114";
}
.flaticon-quotation:before {
    content: "\f115";
}
.flaticon-check:before {
    content: "\f116";
}
.flaticon-quote:before {
    content: "\f117";
}
.flaticon-student:before {
    content: "\f118";
}
.flaticon-email:before {
    content: "\f119";
}
.flaticon-user:before {
    content: "\f11a";
}
.flaticon-checked:before {
    content: "\f11b";
}
.flaticon-phone-call:before {
    content: "\f11c";
}
